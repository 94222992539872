body {
    background-color: #f1f7fc;
    height: 100vh;
}

.App {
    height: 100%;
}

#root {
    height: 100%;
}